import React, { useEffect, useState } from "react";
import axios from "axios";
// import https from 'https';

const API_URL = "https://giangsonwedding.com:8000";

// const agent = new https.Agent({
//   rejectUnauthorized: false  // Disable SSL verification
// });

function VisitCounter() {
  const [count, setCount] = useState(0);

  // const updateCounter = async (newCount) => {
  //   try {
  //     const response = await fetch('/counter', {
  //       method: 'POST',  // Use PUT for updating data
  //       headers: {
  //         'Content-Type': 'application/json',  // Send JSON data
  //       },
  //       body: JSON.stringify({}),  // Send the new counter value
  //     });

  //     if (!response.ok) {
  //       throw new Error('Failed to update counter');
  //     }

  //     const data = await response.json();
  //     setCount(data.count);  // Update the state with the new counter value
  //     console.log('Counter updated:', data);
  //   } catch (error) {
  //     console.error('Update error:', error);
  //   }
  // };

  // const fetchCounter = async () => {
  //   try {
  //     const response = await fetch('/counter');  // This will be proxied to http://127.0.0.1:8000/counter
  //     if (!response.ok) {
  //       throw new Error('Network response was not ok');
  //     }
  //     const data = await response.json();  // assuming the backend returns a JSON response
  //     data.count += 1;
  //     setCount(data.count);  // Update the state with the count
  //     updateCounter();
  //   } catch (error) {
  //     console.error('Fetch error:', error);
  //   }
  // };

  // // Fetch counter when component is mounted
  // useEffect(() => {
  //   fetchCounter();
  // }, []);  // Empty array means it runs only once when the component mounts

  useEffect(() => {
    // Fetch the current counter
    // axios.get(`${API_URL}/counter`)
    axios.get(`${API_URL}/counter`)
      .then((response) => {
        setCount(response.data.count);
      })
      .catch((error) => {
        console.error("Error fetching counter:", error);
      });

    // Increment the counter
    axios.post(`${API_URL}/counter`)
      .then((response) => {
        setCount(response.data.count);
      })
      .catch((error) => {
        console.error("Error incrementing counter:", error);
      });
  }, []);

return (
    <div className="widget">
        {/* <div className="widget-title">
            <h2>
                Góc yêu thương
            </h2>
        </div> */}
        {/* <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexWrap: "wrap" }}> */}

        <p style={{ textAlign: "justify", width: "100%" }}>
            <i className="fa fa-heart" aria-hidden="true" style={{ margin: "5px", color: '#dd6673' }}></i>
            Wow!!!! Đã có {count} khách mời ghé thăm trang này!
            <i className="fa fa-heart" aria-hidden="true" style={{ margin: "5px", color: '#dd6673' }}></i>
          </p>
        {/* </div> */}
    </div>
  );

}

export default VisitCounter;
