import React from 'react'
import SectionTitle from '../SectionTitle'
import sImg1 from '../../images/event/1.jpeg'
import sImg2 from '../../images/event/2.jpeg'
import sImg3 from '../../images/event/3.jpeg'
import LocationMap from '../Modal'


const Events = [
    {
        Simg: sImg1,
        title:'Hôn lễ',
        li1:'Thứ 7, 28/12/2024 10:00',
        li2:'Địa điểm: Tư gia',
        li3:'Thành phố Quảng Ngãi, tỉnh Quảng Ngãi',
        location:'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d18321.940760221696!2d108.78033033527227!3d15.119106566305625!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x316852cd89603939%3A0x2e554f035a6642c3!2zVHAuIFF14bqjbmcgTmfDo2ksIFF14bqjbmcgTmfDo2ksIFZp4buHdCBOYW0!5e0!3m2!1svi!2s!4v1733506888387!5m2!1svi!2s',
    },
    {
        Simg: sImg2,
        title:'Tiệc vu quy',
        li1:'Thứ 7, 28/12/2024 11:00',
        li2:'Địa điểm: Diamond Palace',
        li3:'05 Tôn Đức Thắng, phường Lê Hồng Phong, TP.Quảng Ngãi',
        location:'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3851.4922530362624!2d108.78272795379111!3d15.13128732639814!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3169ad3e9d2c9ddd%3A0xa68b1fa1fea5a1d4!2sDiamond%20Palace!5e0!3m2!1svi!2s!4v1733506948876!5m2!1svi!2s',
    },
    {
        Simg: sImg3,
        title:'Tiệc cưới',
        li1:'Thứ 4, 01/01/2025 18:00',
        li2:'Địa điểm: Long Biên Palace',
        li3:'06 Tân Sơn, phường 12, quận Gò Vấp, TP.Hồ Chí Minh',
        location:'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1165.052232746936!2d106.64950772911453!3d10.830206019797071!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3175297383cad4dd%3A0x1b2093effb566ab2!2zVHJ1bmcgdMOibSBI4buZaSBuZ2jhu4sgLSBUaeG7h2MgQ8aw4bubaSBMb25nIEJpw6puIFBhbGFjZV9Tw6JuIEdvbGYgVMOibiBTxqFuIE5o4bqldA!5e0!3m2!1svi!2s!4v1733507000152!5m2!1svi!2s',
    },

]

const EventSection = (props) => {
    return (
        <section className={`wpo-event-section section-padding ${props.eClass}`} id="events">
            <div className="container">
                <SectionTitle MainTitle={'TIME AND PLACE'} />
                <div className="wpo-event-wrap">
                    <div className="row">
                        {Events.map((event, eitem) => (
                            <div className="col col-lg-4 col-md-6 col-12" key={eitem}>
                                <div className="wpo-event-item">
                                    <div className="wpo-event-img">
                                        <img src={event.Simg} alt=""/>
                                    </div>
                                    <div className="wpo-event-text">
                                        <h2>{event.title}</h2>
                                        <ul>
                                            <li>{event.li1}</li>
                                            <li>{event.li2}</li>
                                            <li>{event.li3}</li>
                                            <li><LocationMap location={event.location}/></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default EventSection;