import React from 'react'
import ModalVideo from 'react-modal-video'
import '../../../node_modules/react-modal-video/scss/modal-video.scss';
import gsVideo from '../../assets/video/gsproposal.mp4'

class VideoModal extends React.Component {

  constructor () {
    super()
    this.state = {
      isOpen: false
    }
    this.openModal = this.openModal.bind(this)
  }

  openModal () {
    this.setState({isOpen: true})
  }

  render () {
    return (
      <div>
        <ModalVideo channel='custom' isOpen={this.state.isOpen} url={gsVideo} onClose={() => this.setState({isOpen: false})} />
          <div className="video-btn">
              <ul>
                  <li>
                    <button className="wrap" onClick={this.openModal}><i className="fi flaticon-play" aria-hidden="true"></i></button>
                  </li>
              </ul>
          </div>
      </div>
    )
  }
}

export default VideoModal;