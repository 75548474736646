import React, { useState, useEffect } from 'react';
import MusicBar from './MusicBar';

const YouTubePlayer = () => {
  const [player, setPlayer] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [trackInfo, setTrackInfo] = useState({
    title: 'Loading...',
    thumbnail: '',
  });

  // Load YouTube Player API
  useEffect(() => {
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    document.body.appendChild(tag);

    window.onYouTubeIframeAPIReady = () => {
      const newPlayer = new window.YT.Player('player-container', {
        height: '0',
        width: '0',
        playerVars: {
          listType: 'playlist',
          list: 'PLcLAEiJxVAuW6eV0s65ye9MQ7l8LHhYpt', // Thay bằng playlist ID của bạn
          autoplay: 0,
          modestbranding: 1,
          rel: 0,
        },
        events: {
          onReady: () => loadTrackInfo(newPlayer),
          onStateChange: (event) => handlePlayerStateChange(event, newPlayer),
        },
      });

      setPlayer(newPlayer);
    };
  }, []);

  // Lấy thông tin bài hát hiện tại
  const loadTrackInfo = (playerInstance) => {
    const videoData = playerInstance.getVideoData();
    setTrackInfo({
      title: videoData.title,
      thumbnail: `https://img.youtube.com/vi/${videoData.video_id}/default.jpg`,
    });
  };

  // Xử lý khi trạng thái player thay đổi
  const handlePlayerStateChange = (event, playerInstance) => {
    if (event.data === window.YT.PlayerState.PLAYING) {
      setIsPlaying(true);
      loadTrackInfo(playerInstance);
    } else if (event.data === window.YT.PlayerState.PAUSED) {
      setIsPlaying(false);
    }
  };

  // Điều khiển phát, tạm dừng, chuyển bài
  const handlePlayPause = () => {
    if (isPlaying) {
      player.pauseVideo();
    } else {
      player.playVideo();
    }
  };

  const handlePrev = () => {
    player.previousVideo();
    loadTrackInfo(player);
  };

  const handleNext = () => {
    player.nextVideo();
    loadTrackInfo(player);
  };

  return (
    <>
      <div id="player-container" style={{ display: 'none' }}></div>
      <MusicBar
        title={trackInfo.title}
        thumbnail={trackInfo.thumbnail}
        isPlaying={isPlaying}
        onPlayPause={handlePlayPause}
        onPrev={handlePrev}
        onNext={handleNext}
      />
    </>
  );
};

export default YouTubePlayer;
