import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import Slide from 'react-reveal/Slide';
import sonImg from '../../images/couple/son.jpg'
import coupleImg2 from '../../images/couple/3.jpg'

class CoupleSection2 extends Component {

    render() {
        return(
            <section className={`couple-section-s2 section-padding ${this.props.cClass}`} id="couple">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col col-xs-12">
                            <div className="couple-area clearfix">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <div className="couple-item">
                                            <div className="couple-img">
                                                <img src={coupleImg2} alt=""/>
                                            </div>
                                            <div className="couple-text">
                                                <h3>Long Giang</h3>
                                                <p>Năm sinh = 2001</p>
                                                <p>Cung Hoàng đạo = Song Tử</p>
                                                <div className="social">
                                                    <ul>
                                                        <li><Link to="https://www.facebook.com/NguyenDinhLongGiang"><i className="ti-facebook"></i></Link></li>
                                                        <li><Link to="https://www.linkedin.com/in/NGUYENDINHLONGGIANG/"><i className="ti-linkedin"></i></Link></li>
                                                        {/* <li><Link to="tel:555-555-5555"><i className="ti-mobile"></i></Link></li> */}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="middle-couple-text">
                                            <Slide bottom cascade>
                                                <h2>
                                                    <span>L</span>
                                                    <span>o</span>
                                                    <span>v</span>
                                                    <span>e</span>
                                                </h2>
                                            </Slide>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="couple-item">
                                            <div className="couple-img">
                                                <img src={sonImg} alt=""/>
                                            </div>
                                            <div className="couple-text">
                                                <h3>Trường Sơn</h3>
                                                <p>Năm sinh = 2001 - 10</p>
                                                <p>Cung Hoàng đạo = Song Tử</p>
                                                <div className="social">
                                                    <ul>
                                                        <li><Link to="https://www.facebook.com/sonnt.skyline/"><i className="ti-facebook"></i></Link></li>
                                                        <li><Link to="https://www.linkedin.com/in/son-nguyen-407b4a7a"><i className="ti-linkedin"></i></Link></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        );
    }
}

export default CoupleSection2;