import React, { Component } from "react";
// import { connect } from "react-redux";
import MobileMenu from "../MobileMenu";
// import min3 from "../../images/love.png";
// import { removeFromCart } from "../../store/actions/action";
import { Link } from 'react-scroll';
import { NavLink } from 'react-router-dom';
import YouTubePlayer from '../../components/MusicPlayer/YouTubePlayer';
import HeaderTopbar from "../HeaderTopbar";
import logo from "../../images/giangson.png";
// import {totalPrice} from "../../utils";

class Header extends Component {

  render() {

    const ClickHandler = () => {
      window.scrollTo(10, 0);
    }

    // const { carts } = this.props;


    return (
      <header id="header" className={this.props.topbarBlock} id="home">
        <HeaderTopbar />
        <div className={`wpo-site-header ${this.props.hclass}`}>
          <nav className="navigation navbar navbar-expand-lg navbar-light">
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-lg-3 col-md-3 col-3 d-lg-none dl-block">
                  <div className="mobail-menu">
                    <MobileMenu/>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-6">
                  <div className="navbar-header">
                    {/* <NavLink onClick={ClickHandler} className="navbar-brand logo" to="/home">Feel<span>i<i className="fa fa-heart" aria-hidden="true"></i></span>ngs</NavLink> */}
                    <NavLink onClick={ClickHandler} className="navbar-brand logo" to="/"><img src={logo} alt="" /></NavLink>
                  </div>
                </div>
                <div className="col-lg-6 col-md-1 col-1">
                  <div id="navbar" className="collapse navbar-collapse navigation-holder">
                    <button className="menu-close"><i className="ti-close"></i></button>
                    <ul className="nav navbar-nav mb-2 mb-lg-0">
                      <li><Link activeClass="active" to="home" spy={true} smooth={true} duration={500} >Home</Link></li>
                      <li><Link activeClass="active" to="couple" spy={true} smooth={true} offset={-100} duration={500} >Couple</Link></li>
                      <li><Link activeClass="active" to="events" spy={true} smooth={true} offset={-80} duration={500} >Events</Link></li>
                      <li><Link activeClass="active" to="gallery" spy={true} smooth={true} duration={500} >Gallery</Link></li>
                    </ul>

                  </div>
                </div>
                <div className="col-lg-3 col-md-2 col-2">
                   <div className="header-right">
                    <YouTubePlayer />
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </header>
    )
  }
}

export default Header;
