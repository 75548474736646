import React from 'react';

const MusicBar = ({ title, thumbnail, isPlaying, onPlayPause, onPrev, onNext }) => {

  return (
    <div id="music-bar">
      <div className="info">
        <i id="img" className="fa fa-music"></i>
        {/* <img
          src="https://via.placeholder.com/25"
          alt="Thumbnail"
        /> */}
        <div id="title">{title}</div>
      </div>
      <div className="controls">
        <button id="prev" onClick={onPrev}>
          <i className="fa fa-step-backward"></i>
        </button>
        <button id="play" onClick={onPlayPause}>
          {isPlaying ? <i className="fa fa-pause"></i> : <i className="fa fa-play"></i>}
        </button>
        <button id="next" onClick={onNext}>
          <i className="fa fa-step-forward"></i>
        </button>
      </div>
    </div>
  );
};

export default MusicBar;
