import React from 'react';
import { BrowserRouter, Routes, Route, } from "react-router-dom";

import Homepage2 from '../HomePage2'
import ErrorPage from '../ErrorPage'
import ContactPage from '../ContactPage'

const AllRoute = () => {

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Homepage2/>} />
          <Route path='home' element={<Homepage2/>} />
          <Route path='404' element={<ErrorPage/>} />
          <Route path='contact' element={<ContactPage/>} />

        </Routes>
      </BrowserRouter>

    </div>
  );
}

export default AllRoute;
