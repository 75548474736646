import React, {Fragment} from 'react';
import Navbar from '../../components/Navbar'
import Hero2 from '../../components/hero2';
import CoupleSection2 from '../../components/CoupleSection2';
import VideoSection from '../../components/VideoSection'
import PortfolioSection2 from '../../components/PortfolioSection2';
import EventSection from '../../components/EventSection';
import Scrollbar from '../../components/scrollbar'
import PartnerSection from '../../components/PartnerSection';
import Footer from '../../components/footer';
import WeddingTime from '../../components/weddingTime';
// import YouTubePlayer from '../../components/MusicPlayer/YouTubePlayer';
// import RandomPrize from '../../components/RandomPrize/RandomPrize';
// import SpinWheel from '../../components/SpinWheel';


const HomePage2 =() => {

    return(
        <Fragment>
            <Navbar/>
            <Hero2 boxClass={'wpo-box-style'} heroClass={'wpo-hero-style-2'}/>
            <WeddingTime/>
            <CoupleSection2/>
            <EventSection/>
            <VideoSection/>
            <PortfolioSection2/>
            {/* <RSVP/> */}
            <PartnerSection/>
            <Footer/>
            <Scrollbar/>
            {/* <YouTubePlayer /> */}
            {/* <RandomPrize /> */}
            {/* <SpinWheel /> */}
        </Fragment>
    )
};

export default HomePage2;